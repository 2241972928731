<script>
import qs from 'qs'

export default {
	inheritAttrs: false,
	props: {
		src: [Object, String],
		imageProps: Object,
		videoProps: Object,
		youtubeVideoProps: Object,
		vimeoVideoProps: Object,
		pdfProps: Object,
		noAlt: Boolean,
		useThumb: Boolean,
		noWebp: Boolean,
		as: String,
	},
	computed: {
		mediaItem() {
			return this.$media(this.src, { noWebp: this.noWebp })
		},
		data() {
			const { type, data } = this.mediaItem
			const thumb = this.useThumb && this.mediaItem.data?.thumb

			let as = null
			let props = {}

			if (!type || type === 'image' || type === 'gif' || thumb) {
				const baseProps = {}
				const { width, height } = data || {}
				if (width && height) baseProps.original = { width, height }

				as = this.as || 'RatioImage'
				props = {
					...baseProps,
					...this.$attrs,
					...(this.imageProps || {}),
					src: thumb || this.mediaItem.src,
				}
			} else if (type === 'video') {
				const videoProps = this.videoProps || {}
				videoProps.videoAttrs = {
					...(videoProps.videoAttrs || {}),
					playsinline: true,
				}
				as = this.as || 'RatioVideo'
				props = {
					...this.$attrs,
					...videoProps,
					src: this.mediaItem.src,
				}
			} else if (type === 'youtube-video' || type === 'vimeo-video') {
				let src = this.mediaItem.url
				const extraProps = type === 'youtube-video' ? this.youtubeVideoProps : this.vimeoVideoProps
				const { urlOptions = this.$attrs.urlOptions || {} } = extraProps || {}

				if (urlOptions) {
					const query = qs.stringify(urlOptions)
					src += query ? `?${query}` : ''
				}

				as = 'RatioIframe'
				props = {
					...this.$attrs,
					...extraProps,
					src: src.toString(),
				}
			} else if (type === 'pdf') {
				as = 'RatioPdf'
				props = {
					...this.$attrs,
					...(this.pdfProps || {}),
					src: this.mediaItem.src,
				}
			}

			if (!this.noAlt && this.mediaItem.alt) props.alt = this.mediaItem.alt

			return {
				as,
				props,
			}
		},
		slotsKeys() {
			return Object.keys(this.$slots).filter((key) => key !== 'default')
		},
	},
	// render(h) {
	// 	const { as, props } = this.data
	// 	if (!as) return h('div', 'Media type not supported')

	// 	console.log(as)

	// 	const isImageOrGif = this.mediaItem.type === 'image' || this.mediaItem.type === 'gif'
	// 	const namedSlotsKeys = Object.keys(this.$slots).filter((key) => key !== 'default')

	// 	return h(
	// 		as,
	// 		{
	// 			...props,
	// 		},
	// 		[
	// 			h(
	// 				'template',
	// 				{ slot: 'default' },
	// 				isImageOrGif ? this.$slots.default : this.$slots[this.mediaItem.type]
	// 			),
	// 			...namedSlotsKeys.map((slotKey) => {
	// 				return h('template', { slot: slotKey }, this.$slots[slotKey])
	// 			}),
	// 		]
	// 	)
	// },
}
</script>

<template>
	<component v-if="data.as" :is="data.as" v-bind="data.props" v-on="$listeners">
		<template #default>
			<slot v-if="mediaItem.type == 'image' || mediaItem.type == 'gif'"></slot>
			<slot v-else :name="mediaItem.type"> </slot>
		</template>
		<template v-for="slotKey of slotsKeys" #[slotKey]>
			<slot :name="slotKey" />
		</template>
	</component>
	<div v-else>Not supported media type</div>
</template>

