<script>
export default {
	props: ['currentZone', 'ProductPage'],
	hookZone: ['after--images', 'bottom-inside', 'bottom'],
	showHook(currentZone, ProductPage) {
		return ProductPage.product.info.templateKey == 'descInfoAndMedia'
	},
	computed: {
		show() {
			return (
				(this.$b.d && this.currentZone == 'after--images') ||
				(this.$b.mt && this.currentZone == 'bottom-inside')
			)
		},
		templateData() {
			return this.ProductPage.product.info.templateData
		},
		attrs() {
			return this.ProductPage.product.attrs || []
		},
		bannerStyle() {
			return {
				background: this.templateData.bgColor,
				color: this.templateData.textColor,
			}
		},
	},
	methods: {
		makeMediaProps(media) {
			if (media.type == 'video') {
				return {
					videoProps: {
						videoAttrs: {
							autoplay: true,
							loop: true,
							muted: true,
							playsinline: true,
						},
					},
					muted: true,
					autoplay: true,
					loop: true,
					playsinline: true,
					as: 'video',
				}
			}
			return {
				as: 'img',
			}
		},
	},
}
</script>

<template>
	<div>
		<div v-if="show" class="mx-n3 px-3" :class="$b.m && 'bordered'">
			<ProductPage-Description :templateData="templateData" :product="ProductPage.product" class="pa-3" />
			<ProductPage-Attributes :attributes="attrs" class="pa-3" />
		</div>
		<div v-if="currentZone == 'bottom'" class="desc-info-and-media" :class="$b.mt && '--mobile'">
			<div class="__banner" :style="bannerStyle">
				<div class="__content">
					<div class="__left">
						<div class="__title">{{ templateData.title }}</div>
						<Media
							style="margin: 0 auto"
							:src="$b.d ? templateData.imgUnderTitle : templateData.imgUnderTitleMobile"
						/>
					</div>
					<div class="__right">
						<Media
							class="__media --f-height"
							v-bind="makeMediaProps($b.d ? templateData.bannerMedia : templateData.bannerMediaMobile)"
							:src="$b.d ? templateData.bannerMedia : templateData.bannerMediaMobile"
						/>
					</div>
				</div>
			</div>

			<div class="__items-wrapper">
				<div
					v-for="(item, n) in templateData.items"
					:key="n"
					class="__item"
					:class="{ '--inverted': item.inverted }"
				>
					<div class="__media-wrapper">
						<Media class="__media" :src="$b.d ? item.image : item.imageMobile" />
					</div>
					<div class="__content">
						<h2 class="__title">{{ item.title }}</h2>
						<div v-html="item.content" class="font-2"></div>
					</div>
				</div>

				<SafeLink
					v-if="$b.m ? templateData.bannerImgMobile : templateData.bannerImg"
					:to="templateData.bannerLink"
				>
					<Media :src="$b.m ? templateData.bannerImgMobile : templateData.bannerImg" fill width="100%" />
				</SafeLink>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.bordered {
	border-top: 1px solid #eee;
}

.desc-info-and-media {
	margin-top: 60px;
	img.__media,
	video.__media {
		border-radius: 24px;
	}
	.__media {
		::v-deep {
			img,
			video {
				border-radius: 24px;
			}
		}
		&.--f-height {
			height: 100%;
		}
	}

	.__banner {
		padding: 28px 0;

		width: 100vw;
		margin-left: calc(-50vw + 50%);
		margin-right: calc(-50vw + 50%);

		.__content {
			max-width: 1200px;
			margin: 0 auto;
			padding: 0 38px;
			display: flex;
			gap: 48px;

			.__left,
			.__right {
				width: 50%;
			}

			.__left {
				display: flex;
				flex-direction: column;
				gap: 12px;

				.__title {
					text-align: center;
					font-size: 3.8rem;
					line-height: 4.3rem;
				}

				.__media {
					margin: 0 auto;
				}
			}

			.__right {
				position: relative;

				.__media {
					position: absolute;
					width: auto;
					scale: 1.4;
					left: 50%;
					transform: translate(-50%);
				}
			}
		}
	}

	.__items-wrapper {
		margin-top: 56px;
		display: flex;
		flex-direction: column;
		gap: 32px;
	}
	.__item {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8%;

		.__media-wrapper,
		.__content {
			width: 35%;
		}

		.__content {
			text-align: left;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 12px;

			.__title {
				font-size: 1.4rem;
				line-height: 1.9rem;
			}
		}

		&.--inverted {
			flex-direction: row-reverse;
		}
	}

	&.--mobile {
		margin-top: 12px;
		.__banner {
			.__content {
				display: block;
				.__left,
				.__right {
					width: unset;
				}
				.__left {
					.__title {
						font-size: 2.6rem;
						line-height: 3.1rem;
					}
				}
				.__right {
					.__media {
						position: static;
						scale: 1;
						transform: none;
						width: 100%;
					}
				}
			}
		}
		.__items-wrapper {
			margin-top: 24px;
		}
		.__item {
			flex-direction: column;
			gap: 18px;
			.__media-wrapper,
			.__content {
				width: 100%;
			}
		}
	}
}
</style>

