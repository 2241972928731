import productMixin from './product-mixin'
import shopProductOptions from '@/v-shop/mixins/shop-product-options-mixin'

export default {
	mixins: [productMixin('card'), shopProductOptions()],
	props: {
		type: String,
		product: Object,
		listId: String,
		listIndex: Number,
		timerProduct: { type: Boolean, default: false },
		verticalCard: { type: Boolean, default: true },
		featured: { type: Boolean, default: false },
		small: { type: Boolean, default: false },
		mobileBtn: { type: Boolean, default: false },
		forMobileShop: { type: Boolean, default: false },
		pbForBtnHover: { type: String, default: '60px' },
		upsellCard: { type: Boolean, default: false },
	},
	data() {
		return {
			firstImageHover: false,
			configData: this.$srv('productCardType', {}),
			showBtnHoverMobile: false,
			hover: false,
			qty: 0,
			selectedVariantId: null,
			showTooltip: false,
			tooltipTimeout: null,
		}
	},
	computed: {
		mainImage() {
			let img = this.product.images[0].square
			img.alt = this.product.name
			return img
		},
		secondImage() {
			let img = this.product.images[1]?.square
			if (img) img.alt = this.product.name
			return img
		},
		textTag() {
			if (this.textTags.length) return this.textTags[0].text
			return null
		},
		alignment() {
			return this.configData.alignment || 'left'
		},
		// btnType
		actionType() {
			if (this.upsellCard || this.spoHideBuyBtn) return null
			return this.$b.d ? this.configData.btnDesktop : this.configData.btnMobile
		},
		hasVisibleAction() {
			return this.actionType == 'btn' || this.actionType == 'qty'
		},
		hasHoverAction() {
			return this.actionType == 'btnHover'
		},
		hasAction() {
			return this.hasVisibleAction || this.hasHoverAction
		},
		cardBorder() {
			return !!this.configData.cardBorder
		},
		imgHover() {
			return this.$b.d
		},
		hoverForBtn() {
			return 'calc(100% + ' + this.pbForBtnHover + ')'
		},
		smallWrapperClass() {
			const design = this.type?.toLowerCase() || this.configData.name.toLowerCase() || 'design1'

			if (this.$b.m) {
				if (!this.forMobileShop) {
					if (this.hasHoverAction) return `product-card-${design}-small-wrapper-mobile-forBtn`
					else return `product-card-${design}-small-wrapper-mobile`
				} else {
					if (this.hasHoverAction) return `product-card-${design}-small-wrapper-forBtn`
					else return `product-card-${design}-small-wrapper`
				}
			}

			if (this.$b.t) {
				if (this.hasHoverAction) return `product-card-${design}-small-wrapper-forBtn`
				else return `product-card-${design}-small-wrapper-mobile`
			}

			if (this.hasHoverAction) return `product-card-${design}-small-wrapper-forBtn`
			else return `product-card-${design}-small-wrapper`
		},
		verticalWrapperClasses() {
			const design = this.type?.toLowerCase() || this.configData.name.toLowerCase() || 'design1'
			const cardClass = `product-card-${design}-vertical-wrapper`
			const hasAttrsTagsConfig = this.$srv('productCardTags', false)

			return [
				`h100 ${cardClass}`,
				{
					[`${cardClass}-forBtn`]: this.hasHoverAction,
					[`${cardClass}--for-btn-with-tags`]: this.hasHoverAction && hasAttrsTagsConfig,
				},
			]
		},
		verticalCardClasses() {
			return 'd-flex flex-column base rounded-md overflow-hidden product-card pb-2'
		},
		horizontalCardClasses() {
			return 'd-flex overflow-hidden base product-card py-2'
		},
		hasVariantSelector() {
			return (
				this.configData.showVariantSelector &&
				!this.product.hasUniqueVariant &&
				this.product.variants[0].attrs.length == 1
			)
		},
		canBeAddedToCart() {
			return (
				!this.outOfStock &&
				!this.isCustomizable &&
				(this.product.hasUniqueVariant || this.hasVariantSelector) &&
				(this.$b.m && this.hasHoverAction ? this.hover : true)
			)
		},
	},
	methods: {
		async cardClick() {
			this.$eventer().trigger('product:click', {
				product: this.product,
				variant: this.selectedVariant,
				listId: this.listId,
				index: this.listIndex,
			})
			this.$emit('card:click', this.product)
		},

		onLinkClickMobile(navigate, $event) {
			if (this.hasHoverAction) {
				if (this.hover) navigate($event)
				else {
					this.hover = true
					$event.preventDefault()
				}
			} else navigate($event)
		},
		onLinkBlurMobile() {
			if (this.hasHoverAction) this.hover = false
			else null
		},
	},
}

